<template>
  <AuthLayout>
    <main>
      <div class="container">
        <div style="margin-top: 15vh" class="row justify-content-center">
          <div class="col-lg-7">
            <div class="card shadow-lg border-0 rounded-lg mt-5">
              <div class="card-header justify-content-center">
                <img
                  class="mx-2"
                  style="width: 30px"
                  src="../assets/logo2.png"
                />
                <h3 class="font-weight-light my-4">Create Account</h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="register()">
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="small mb-1" for="inputFirstName"
                          >First Name</label
                        ><input
                          class="form-control py-4"
                          id="inputFirstName"
                          type="text"
                          placeholder="Enter first name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="small mb-1" for="inputLastName"
                          >Last Name</label
                        ><input
                          class="form-control py-4"
                          id="inputLastName"
                          type="text"
                          placeholder="Enter last name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email</label
                    ><input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      type="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="small mb-1" for="inputPassword"
                          >Password</label
                        ><input
                          class="form-control py-4"
                          id="inputPassword"
                          type="password"
                          placeholder="Enter password"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="small mb-1" for="inputConfirmPassword"
                          >Confirm Password</label
                        ><input
                          class="form-control py-4"
                          id="inputConfirmPassword"
                          type="password"
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-4 mb-0">
                    <button
                      v-if="loading"
                      class="btn btn-primary btn-block disabled"
                    >
                      <div
                        class="spinner-border spinner-border-sm mx-1 text-light"
                        role="status"
                      ></div>
                      Loading...
                    </button>
                    <button
                      v-else
                      class="btn btn-primary btn-block"
                      type="submit"
                    >
                      Create Account
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center">
                <div class="small">
                  <router-link to="/login"
                    >Have an account? Go to login</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AuthLayout>
</template>

<script>
import AuthLayout from "../layouts/AuthLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMainStore } from "../stores/main";
import { useAuthStore } from "../stores/authentication";

export default {
  name: "Register",
  components: { AuthLayout },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useMainStore, ["isLoggedIn"]),
    ...mapState(useAuthStore, ["loading"]),
  },

  methods: {
    ...mapActions(useAuthStore, ["register"]),
  },
};
</script>

<style>
</style>
