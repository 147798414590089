<template>
  <div id="layoutAuthentication" class="alert-primary">
    <div id="layoutAuthentication_content">
      <slot />
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="footer mt-auto footer-dark">
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-md-6 text-dark text-center small">
              Copyright &copy; CHI Plc 2022
            </div> -->
            <!-- <div class="col-md-6 text-md-right small">
              <a href="#!">Privacy Policy</a>
              &middot;
              <a href="#!">Terms &amp; Conditions</a>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { useMainStore } from "../stores/main";
import { mapState } from "pinia";

export default {
  name: "AothLayout",
  components: {},
  data() {
    return {
      toggleNav: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ["isLoggedIn"]),
  },

  methods: {},

  created() {
    // if (this.isLoggedIn) {
    //   this.$router.push("/");
    // }
  },
};
</script>

<style></style>
